export const colleges = [
  {
    "college": "Indian Institute of Technology Madras"
  },
  {
    "college": "Indian Institute of Technology Bombay"
  },
  {
    "college": "Indian Institute of Technology Kanpur"
  },
  {
    "college": "University of Delhi"
  },
  {
    "college": "Indian Institute of Technology Delhi"
  },
  {
    "college": "Indian Institute of Technology Kharagpur"
  },
  {
    "college": "Lovely Professional University"
  },
  {
    "college": "Vellore Institute of Technology"
  },
  {
    "college": "Indian Institute of Science"
  },
  {
    "college": "SRM Institute of Science and Technology"
  },
  {
    "college": "Savitribai Phule Pune University"
  },
  {
    "college": "Manipal Academy of Higher Education"
  },
  {
    "college": "Amrita Vishwa Vidyapeetham"
  },
  {
    "college": "All India Institute of Medical Sciences Delhi"
  },
  {
    "college": "Tata Institute of Fundamental Research"
  },
  {
    "college": "Narsee Monjee Institute of Management and Higher Studies"
  },
  {
    "college": "Birla Institute of Technology and Science"
  },
  {
    "college": "International Institute of Information Technology, Hyderabad"
  },
  {
    "college": "University of Kerala"
  },
  {
    "college": "Amity University"
  },
  {
    "college": "Jawaharlal Nehru University"
  },
  {
    "college": "Anna University"
  },
  {
    "college": "Tata Institute of Social Sciences"
  },
  {
    "college": "Indian Institute of Technology Guwahati"
  },
  {
    "college": "Indian Institute of Technology Roorkee"
  },
  {
    "college": "Chandigarh University"
  },
  {
    "college": "Indian Statistical Institute"
  },
  {
    "college": "University of Mumbai"
  },
  {
    "college": "Mahatma Gandhi University"
  },
  {
    "college": "Banaras Hindu University"
  },
  {
    "college": "Christ University"
  },
  {
    "college": "Guru Gobind Singh Indraprastha University"
  },
  {
    "college": "Gandhi Institute of Technology and Management"
  },
  {
    "college": "Aligarh Muslim University"
  },
  {
    "college": "Dr. A.P.J. Abdul Kalam Technical University"
  },
  {
    "college": "University of Calicut"
  },
  {
    "college": "The Maharaja Sayajirao University of Baroda"
  },
  {
    "college": "Indian Institute of Technology Hyderabad"
  },
  {
    "college": "Tamil Nadu Agricultural University"
  },
  {
    "college": "Panjab University"
  },
  {
    "college": "Osmania University"
  },
  {
    "college": "Cochin University of Science and Technology"
  },
  {
    "college": "Thapar Institute of Engineering and Technology"
  },
  {
    "college": "Chitkara University, Punjab"
  },
  {
    "college": "National Institute of Technology, Tiruchirappalli"
  },
  {
    "college": "Jamia Millia Islamia"
  },
  {
    "college": "University of Lucknow"
  },
  {
    "college": "Gujarat University"
  },
  {
    "college": "Jadavpur University"
  },
  {
    "college": "Visvesvaraya Technological University"
  },
  {
    "college": "University of Madras"
  },
  {
    "college": "Indian Institute of Technology Gandhinagar"
  },
  {
    "college": "University of Calcutta"
  },
  {
    "college": "National Institute of Design"
  },
  {
    "college": "Chhatrapati Shahu Ji Maharaj University"
  },
  {
    "college": "Kurukshetra University"
  },
  {
    "college": "Ashoka University"
  },
  {
    "college": "University of Petroleum and Energy Studies"
  },
  {
    "college": "Gujarat Technological University"
  },
  {
    "college": "National Institute of Technology, Calicut"
  },
  {
    "college": "SASTRA University"
  },
  {
    "college": "University of Rajasthan"
  },
  {
    "college": "University of Allahabad"
  },
  {
    "college": "APJ Abdul Kalam Technological University"
  },
  {
    "college": "National Institute of Technology, Rourkela"
  },
  {
    "college": "Chennai Mathematical Institute"
  },
  {
    "college": "National Law School of India University"
  },
  {
    "college": "Sharda University"
  },
  {
    "college": "O.P. Jindal Global University"
  },
  {
    "college": "Indian Institute of Science Education and Research, Pune"
  },
  {
    "college": "Rajiv Gandhi Proudyogiki Vishwavidyalaya"
  },
  {
    "college": "International Institute of Information Technology Bangalore"
  },
  {
    "college": "University of Hyderabad"
  },
  {
    "college": "Jiwaji University"
  },
  {
    "college": "Indian Institute of Technology Mandi"
  },
  {
    "college": "Bharathidasan University"
  },
  {
    "college": "National Institute of Technology, Karnataka"
  },
  {
    "college": "KIIT University"
  },
  {
    "college": "Delhi Technological University"
  },
  {
    "college": "Jain University"
  },
  {
    "college": "Indian Institute of Technology Bhubaneswar"
  },
  {
    "college": "Indian Institute of Information Technology Allahabad"
  },
  {
    "college": "Dr. Vishwanath Karad MIT World Peace University"
  },
  {
    "college": "Birla Institute of Technology"
  },
  {
    "college": "Indira Gandhi Institute of Development Research"
  },
  {
    "college": "Veer Narmad South Gujarat University"
  },
  {
    "college": "National Institute of Fashion Technology"
  },
  {
    "college": "Bharati Vidyapeeth Deemed University"
  },
  {
    "college": "Lalit Narayan Mithila University"
  },
  {
    "college": "West Bengal University of Health Sciences"
  },
  {
    "college": "Maulana Abul Kalam Azad University of Technology, West Bengal"
  },
  {
    "college": "Ahmedabad University"
  },
  {
    "college": "Indian Institute of Technology, BHU"
  },
  {
    "college": "University of Mysore"
  },
  {
    "college": "Nirma University"
  },
  {
    "college": "Indian Institute of Foreign Trade"
  },
  {
    "college": "Indian Institute of Space Science and Technology"
  },
  {
    "college": "Post Graduate Institute of Medical Education and Research"
  },
  {
    "college": "Hemwati Nandan Bahuguna Garhwal University"
  },
  {
    "college": "Indian Agricultural Research Institute"
  },
  {
    "college": "Motilal Nehru National Institute of Technology Allahabad"
  },
  {
    "college": "Indraprastha Institute of Information Technology"
  },
  {
    "college": "Azim Premji University"
  },
  {
    "college": "Indian School of Mines"
  },
  {
    "college": "Gauhati University"
  },
  {
    "college": "National Institute of Mental Health and Neuro Sciences"
  },
  {
    "college": "National Institute of Technology, Silchar"
  },
  {
    "college": "Dr. B R Ambedkar National Institute of Technology Jalandhar"
  },
  {
    "college": "Jawaharlal Nehru Centre for Advanced Scientific Research"
  },
  {
    "college": "Jawaharlal Nehru Technological University"
  },
  {
    "college": "Saurashtra University"
  },
  {
    "college": "Devi Ahilya Vishwavidyalaya"
  },
  {
    "college": "North Eastern Hill University"
  },
  {
    "college": "Andhra University"
  },
  {
    "college": "Visva-Bharati University"
  },
  {
    "college": "Kerala Agricultural University"
  },
  {
    "college": "Jawaharlal Institute of Postgraduate Medical Education & Research"
  },
  {
    "college": "Parul University"
  },
  {
    "college": "Kannur University"
  },
  {
    "college": "Maharaja Krishnakumarsinhji Bhavnagar University"
  },
  {
    "college": "Indian Institute of Technology Jodhpur"
  },
  {
    "college": "Jamia Hamdard"
  },
  {
    "college": "Saveetha Institute of Medical and Technical Sciences"
  },
  {
    "college": "Bharathiar University"
  },
  {
    "college": "Himachal Pradesh University"
  },
  {
    "college": "Indian Institute of Science Education and Research, Kolkata"
  },
  {
    "college": "Indian Institute of Technology Indore"
  },
  {
    "college": "Pondicherry University"
  },
  {
    "college": "Sathyabama Institute of Science and Technology"
  },
  {
    "college": "University of Kashmir"
  },
  {
    "college": "CEPT University"
  },
  {
    "college": "Indian Institute of Engineering Science and Technology, Shibpur"
  },
  {
    "college": "Annamalai University"
  },
  {
    "college": "PES University"
  },
  {
    "college": "University of Kalyani"
  },
  {
    "college": "Sanjay Gandhi Post Graduate Institute of Medical Sciences"
  },
  {
    "college": "Shiv Nadar University"
  },
  {
    "college": "Jaypee Institute of Information Technology"
  },
  {
    "college": "Karunya Institute of Technology and Sciences"
  },
  {
    "college": "K L University"
  },
  {
    "college": "Barkatullah University"
  },
  {
    "college": "Dhirubhai Ambani Institute of Information and Communication Technology"
  },
  {
    "college": "University of Engineering and Management, Kolkata"
  },
  {
    "college": "Graphic Era University"
  },
  {
    "college": "Sardar Vallabhbhai National Institute of Technology, Surat"
  },
  {
    "college": "Madurai Kamaraj University"
  },
  {
    "college": "ITM University Raipur"
  },
  {
    "college": "Rashtriya Sanskrit Sansthan University"
  },
  {
    "college": "Pandit Ravishankar Shukla University"
  },
  {
    "college": "Sardar Patel University"
  },
  {
    "college": "Chaudhary Charan Singh University"
  },
  {
    "college": "University of North Bengal"
  },
  {
    "college": "Shivaji University"
  },
  {
    "college": "Symbiosis International University"
  },
  {
    "college": "All India Institute of Medical Sciences Raipur"
  },
  {
    "college": "Sant Gadge Baba Amravati University"
  },
  {
    "college": "All India Institute of Medical Sciences Patna"
  },
  {
    "college": "National Institute of Technology, Warangal"
  },
  {
    "college": "Indian Institute of Technology Ropar"
  },
  {
    "college": "Rabindra Bharati University"
  },
  {
    "college": "Babasaheb Bhimrao Ambedkar Bihar University"
  },
  {
    "college": "Indian Institute of Science Education and Research, Thiruvananthapuram"
  },
  {
    "college": "Bennett University"
  },
  {
    "college": "Rajiv Gandhi University of Health Sciences"
  },
  {
    "college": "Vel Tech Rangarajan Dr. Sagunthala R&D Institute of Science and Technology"
  },
  {
    "college": "Kavayitri Bahinabai Chaudhari North Maharashtra University"
  },
  {
    "college": "Indian Institute of Technology Patna"
  },
  {
    "college": "Malaviya National Institute of Technology, Jaipur"
  },
  {
    "college": "Hemchandracharya North Gujarat University"
  },
  {
    "college": "University of Burdwan"
  },
  {
    "college": "Govind Ballabh Pant University of Agriculture and Technology"
  },
  {
    "college": "Babasaheb Bhimrao Ambedkar University"
  },
  {
    "college": "Dibrugarh University"
  },
  {
    "college": "BML Munjal University"
  },
  {
    "college": "Centurion University of Technology and Management"
  },
  {
    "college": "Maulana Azad National Institute of Technology"
  },
  {
    "college": "Mangalore University"
  },
  {
    "college": "Punjabi University Patiala"
  },
  {
    "college": "International Institute for Population Sciences"
  },
  {
    "college": "Jawaharlal Nehru Technological University, Kakinada"
  },
  {
    "college": "M.J.P. Rohilkhand University"
  },
  {
    "college": "Bangalore University"
  },
  {
    "college": "Rani Durgavati Vishwavidyalaya"
  },
  {
    "college": "Dr. Ram Manohar Lohia Avadh University"
  },
  {
    "college": "The LNM Institute of Information Technology"
  },
  {
    "college": "Rajasthan University of Health Sciences"
  },
  {
    "college": "Indian Institute of Science Education and Research, Bhopal"
  },
  {
    "college": "Nagaland University"
  },
  {
    "college": "Sri Ramachandra Institute of Higher Education and Research"
  },
  {
    "college": "Rajiv Gandhi University of Knowledge Technologies"
  },
  {
    "college": "Alliance University"
  },
  {
    "college": "Presidency University"
  },
  {
    "college": "Brainware University"
  },
  {
    "college": "Maharashtra University of Health Sciences"
  },
  {
    "college": "Deen Dayal Upadhyay Gorakhpur University"
  },
  {
    "college": "Baba Farid University of Health Sciences"
  },
  {
    "college": "Vinayaka Mission's Research Foundation"
  },
  {
    "college": "Uttarakhand Technical University"
  },
  {
    "college": "Swami Ramanand Teerth Marathwada University"
  },
  {
    "college": "National Institute of Technology, Durgapur"
  },
  {
    "college": "Flame University"
  },
  {
    "college": "Dr. Bhimrao Ambedkar University"
  },
  {
    "college": "Kerala University of Fisheries and Ocean Studies"
  },
  {
    "college": "Invertis University"
  },
  {
    "college": "Doon University"
  },
  {
    "college": "All India Institute of Medical Sciences Bhopal"
  },
  {
    "college": "South Asian University"
  },
  {
    "college": "National Institute of Technology, Hamirpur"
  },
  {
    "college": "Banasthali Vidyapith"
  },
  {
    "college": "National Forensic Sciences University"
  },
  {
    "college": "Central University of Kashmir"
  },
  {
    "college": "JSS Academy of Higher Education and Research"
  },
  {
    "college": "Mohanlal Sukhadia University"
  },
  {
    "college": "Bundelkhand University"
  },
  {
    "college": "All India Institute of Medical Sciences Jodhpur"
  },
  {
    "college": "Karnatak University"
  },
  {
    "college": "Sree Chitra Thirunal Institute of Medical Sciences and Technology"
  },
  {
    "college": "Gandhigram Rural University"
  },
  {
    "college": "Siksha 'O' Anusandhan"
  },
  {
    "college": "Jaypee University of Information Technology"
  },
  {
    "college": "NITTE University"
  },
  {
    "college": "Alagappa University"
  },
  {
    "college": "Tamil Nadu Veterinary and Animal Sciences University"
  },
  {
    "college": "VIT Bhopal University"
  },
  {
    "college": "Gujarat National Law University"
  },
  {
    "college": "Punjab Agricultural University"
  },
  {
    "college": "Kerala University of Health Sciences"
  },
  {
    "college": "National Institute of Educational Planning and Administration"
  },
  {
    "college": "Goa University"
  },
  {
    "college": "Acharya N.G. Ranga Agricultural University"
  },
  {
    "college": "Avinashilingam Institute for Home Science and Higher Education for Women"
  },
  {
    "college": "Acharya Nagarjuna University"
  },
  {
    "college": "Hindustan Institute of Technology and Science"
  },
  {
    "college": "Indian Institute of Science Education and Research, Mohali"
  },
  {
    "college": "Assam Agricultural University"
  },
  {
    "college": "Rashtrasant Tukadoji Maharaj Nagpur University"
  },
  {
    "college": "REVA University"
  },
  {
    "college": "Indian Maritime University"
  },
  {
    "college": "Gujarat Vidyapith"
  },
  {
    "college": "Sarguja University"
  },
  {
    "college": "Institute of Chemical Technology"
  },
  {
    "college": "Janardan Rai Nagar Rajasthan Vidhyapeeth University"
  },
  {
    "college": "Maulana Azad National Urdu University"
  },
  {
    "college": "Rajiv Gandhi University"
  },
  {
    "college": "The English and Foreign Languages University"
  },
  {
    "college": "Visvesvaraya National Institute of Technology"
  },
  {
    "college": "Motherhood University"
  },
  {
    "college": "Tamil Nadu Dr. M.G.R.Medical University"
  },
  {
    "college": "Pandit Deendayal Petroleum University"
  },
  {
    "college": "National Law University, Delhi"
  },
  {
    "college": "Padmashree Dr. D.Y. Patil Vidyapith"
  },
  {
    "college": "King George's Medical University"
  },
  {
    "college": "TERI School of Advanced Studies"
  },
  {
    "college": "Dayalbagh Educational Institute"
  },
  {
    "college": "Vidyasagar University"
  },
  {
    "college": "Cotton University"
  },
  {
    "college": "Makhanlal Chaturvedi Rashtriya Patrakarita Vishwavidyalaya"
  },
  {
    "college": "Sher-e-Kashmir University of Agricultural Sciences and Technology of Kashmir"
  },
  {
    "college": "The West Bengal National University of Juridical Sciences"
  },
  {
    "college": "Galgotias University"
  },
  {
    "college": "Kuvempu University"
  },
  {
    "college": "Sikkim Manipal University"
  },
  {
    "college": "DIT University"
  },
  {
    "college": "Sri Krishnadevaraya University"
  },
  {
    "college": "University of Jammu"
  },
  {
    "college": "University of Agricultural Sciences, Bangalore"
  },
  {
    "college": "Patna University"
  },
  {
    "college": "National Institute of Technology, Jamshedpur"
  },
  {
    "college": "Indian Veterinary Research Institute"
  },
  {
    "college": "Dharmsinh Desai University"
  },
  {
    "college": "Mizoram University"
  },
  {
    "college": "Assam Down Town University"
  },
  {
    "college": "Vels University"
  },
  {
    "college": "Dr. D.Y. Patil Vidyapeeth"
  },
  {
    "college": "Chaudhary Charan Singh Haryana Agricultural University"
  },
  {
    "college": "Gokhale Institute of Politics and Economics"
  },
  {
    "college": "Islamic University of Science and Technology"
  },
  {
    "college": "National Institute of Technology, Raipur"
  },
  {
    "college": "National Institute of Technology, Agartala"
  },
  {
    "college": "Ambedkar University Delhi"
  },
  {
    "college": "Jaipur National University"
  },
  {
    "college": "National Institute of Technology, Patna"
  },
  {
    "college": "Dr. Y.S. Parmar University of Horticulture and Forestry"
  },
  {
    "college": "Bidhan Chandra Krishi Viswavidyalaya"
  },
  {
    "college": "Assam Science and Technology University"
  },
  {
    "college": "PEC University of Technology"
  },
  {
    "college": "Awadhesh Pratap Singh University"
  },
  {
    "college": "National Institute of Technology, Kurukshetra"
  },
  {
    "college": "All India Institute of Medical Sciences Bhubaneswar"
  },
  {
    "college": "Assam Don Bosco University"
  },
  {
    "college": "Graphic Era Hill University"
  },
  {
    "college": "Shoolini University of Biotechnology and Management Sciences"
  },
  {
    "college": "Central Institute of Fisheries Education"
  },
  {
    "college": "Indian Institute of Information Technology and Management Gwalior"
  },
  {
    "college": "Veer Surendra Sai University of Technology"
  },
  {
    "college": "Ganpat University"
  },
  {
    "college": "Atal Bihari Vajpayee Vishwavidyalaya"
  },
  {
    "college": "Integral University"
  },
  {
    "college": "School of Planning and Architecture, Delhi"
  },
  {
    "college": "Periyar University"
  },
  {
    "college": "NALSAR University of Law"
  },
  {
    "college": "Utkal University"
  },
  {
    "college": "Maharishi Markandeshwar University, Mullana"
  },
  {
    "college": "Dr. Babasaheb Ambedkar Marathwada University"
  },
  {
    "college": "Tilka Manjhi Bhagalpur University"
  },
  {
    "college": "CMR University"
  },
  {
    "college": "Rama University"
  },
  {
    "college": "All India Institute of Medical Sciences Rishikesh"
  },
  {
    "college": "Punjab Technical University"
  },
  {
    "college": "Tamil Nadu Teacher Education University"
  },
  {
    "college": "Assam University"
  },
  {
    "college": "The Northcap University"
  },
  {
    "college": "Tamil Nadu Dr Ambedkar Law University"
  },
  {
    "college": "Aliah University"
  },
  {
    "college": "GLA University"
  },
  {
    "college": "Mahatma Gandhi Kashi Vidyapeeth"
  },
  {
    "college": "Kalasalingam Academy of Research and Education"
  },
  {
    "college": "Vikram University"
  },
  {
    "college": "National Institute of Technology, Srinagar"
  },
  {
    "college": "Shreemati Nathibai Damodar Thackersey Women's University"
  },
  {
    "college": "Sri Dev Suman Uttarakhand University"
  },
  {
    "college": "National Law University, Jodhpur"
  },
  {
    "college": "Ranchi University"
  },
  {
    "college": "Noida International University"
  },
  {
    "college": "Dr. Harisingh Gour Vishwavidyalaya Sagar"
  },
  {
    "college": "Ramaiah University of Applied Sciences"
  },
  {
    "college": "Shree Guru Gobind Singh Tricentenary University"
  },
  {
    "college": "Yenepoya University"
  },
  {
    "college": "Vishwakarma University"
  },
  {
    "college": "PDPM Indian Institute of Information Technology, Design and Manufacturing"
  },
  {
    "college": "NIIT University"
  },
  {
    "college": "Sri Sathya Sai Institute of Higher Learning"
  },
  {
    "college": "Vinoba Bhave University"
  },
  {
    "college": "Presidency University"
  },
  {
    "college": "Odisha University of Agriculture and Technology"
  },
  {
    "college": "Gautam Buddha University"
  },
  {
    "college": "Rajasthan Technical University Kota"
  },
  {
    "college": "Pravara Institute of Medical Sciences"
  },
  {
    "college": "Shri Mata Vaishno Devi University"
  },
  {
    "college": "Guru Jambheshwar University of Science and Technology"
  },
  {
    "college": "Central University of Rajasthan"
  },
  {
    "college": "Tezpur University"
  },
  {
    "college": "Biju Patnaik University of Technology"
  },
  {
    "college": "GD Goenka University"
  },
  {
    "college": "Suresh Gyan Vihar University"
  },
  {
    "college": "National Institute of Technology, Meghalaya"
  },
  {
    "college": "XIM University"
  },
  {
    "college": "Gulbarga University"
  },
  {
    "college": "Kakatiya University"
  },
  {
    "college": "Manonmaniam Sundaranar University"
  },
  {
    "college": "JECRC University"
  },
  {
    "college": "Dr. Babasaheb Ambedkar Technological University"
  },
  {
    "college": "Indian Institute of Information Technology, Design and Manufacturing"
  },
  {
    "college": "Teerthanker Mahaveer University"
  },
  {
    "college": "University of Kota"
  },
  {
    "college": "Central University of South Bihar"
  },
  {
    "college": "Tripura University"
  },
  {
    "college": "Central University of Tamil Nadu"
  },
  {
    "college": "Ramakrishna Mission Vivekananda Educational and Research Institute"
  },
  {
    "college": "Bharath Institute of Higher Education and Research"
  },
  {
    "college": "IFHE Hyderabad"
  },
  {
    "college": "National Dairy Research Institute"
  },
  {
    "college": "Rajiv Gandhi Institute of Petroleum Technology"
  },
  {
    "college": "Anand Agricultural University"
  },
  {
    "college": "Jai Narain Vyas University"
  },
  {
    "college": "Palamuru University"
  },
  {
    "college": "The National University of Advanced Legal Studies"
  },
  {
    "college": "Dev Sanskriti Vishwavidyalaya"
  },
  {
    "college": "Sri Venkateswara University"
  },
  {
    "college": "National Institute of Pharmaceutical Education and Research, S.A.S. Nagar"
  },
  {
    "college": "Central University of Haryana"
  },
  {
    "college": "Uttaranchal University"
  },
  {
    "college": "Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya"
  },
  {
    "college": "Nalanda University"
  },
  {
    "college": "Thiruvalluvar University"
  },
  {
    "college": "Quantum University"
  },
  {
    "college": "Adamas University"
  },
  {
    "college": "Chhattisgarh Swami Vivekananda Technical University"
  },
  {
    "college": "Pt. Bhagwat Dayal Sharma University of Health Sciences"
  },
  {
    "college": "Central University of Himachal Pradesh"
  },
  {
    "college": "Gujarat Ayurved University"
  },
  {
    "college": "Vignan University"
  },
  {
    "college": "Central University of Punjab"
  },
  {
    "college": "Dr. M.G.R. Educational and Research Institute"
  },
  {
    "college": "K.R. Mangalam University"
  },
  {
    "college": "Kumaun University"
  },
  {
    "college": "Glocal University"
  },
  {
    "college": "Sikkim University"
  },
  {
    "college": "Swami Vivekanand Subharti University"
  },
  {
    "college": "Mody University of Science and Technology"
  },
  {
    "college": "Bhakta Kavi Narsinh Mehta University"
  },
  {
    "college": "Baba Mastnath University"
  },
  {
    "college": "Manipur University"
  },
  {
    "college": "B.S. Abdur Rahman Crescent Institute of Science and Technology"
  },
  {
    "college": "KLE Technological University"
  },
  {
    "college": "North Eastern Regional Institute of Science and Technology"
  },
  {
    "college": "Indian Institute of Information Technology, Kottayam"
  },
  {
    "college": "University of Gour Banga"
  },
  {
    "college": "Sam Higginbottom Institute of Agriculture, Technology and Sciences"
  },
  {
    "college": "Jawaharlal Nehru Technological University, Anantapur"
  },
  {
    "college": "RK University"
  },
  {
    "college": "IFTM University"
  },
  {
    "college": "Jawaharlal Nehru Krishi Vishwavidyalaya"
  },
  {
    "college": "The Indian Law Institute"
  },
  {
    "college": "Dr. Rajendra Prasad Central Agricultural University"
  },
  {
    "college": "Guru Ghasidas Vishwavidyalaya"
  },
  {
    "college": "Dr. N.T.R. University of Health Sciences"
  },
  {
    "college": "ITM University Gwalior"
  },
  {
    "college": "The IIS University"
  },
  {
    "college": "Adikavi Nannaya University"
  },
  {
    "college": "J.C. Bose University of Science and Technology"
  },
  {
    "college": "University of Agricultural Sciences, Dharwad"
  },
  {
    "college": "Sushant University"
  },
  {
    "college": "National Law Institute University"
  },
  {
    "college": "Kerala Veterinary and Animal Sciences University"
  },
  {
    "college": "SRM University Haryana"
  },
  {
    "college": "Madan Mohan Malaviya University of Technology"
  },
  {
    "college": "Central University of Odisha"
  },
  {
    "college": "Rashtriya Raksha University"
  },
  {
    "college": "Veer Bahadur Singh Purvanchal University"
  },
  {
    "college": "Sri Siddhartha Academy of Higher Education"
  },
  {
    "college": "Pandit Deendayal Upadhyaya Shekhawati University"
  },
  {
    "college": "Nizam's Institute of Medical Sciences"
  },
  {
    "college": "National Institute of Technology Delhi"
  },
  {
    "college": "Maharaja Chhatrasal Bundelkhand University"
  },
  {
    "college": "Kerala Kalamandalam"
  },
  {
    "college": "Sant Longowal Institute of Engineering and Technology"
  },
  {
    "college": "JS University"
  },
  {
    "college": "KLE University"
  },
  {
    "college": "Tumkur University"
  },
  {
    "college": "NIMS University"
  },
  {
    "college": "Jai Prakash Vishwavidyalaya"
  },
  {
    "college": "Shri Govind Guru University"
  },
  {
    "college": "Hidayatullah National Law University"
  },
  {
    "college": "Anant National University"
  },
  {
    "college": "Central University of Kerala"
  },
  {
    "college": "Junagadh Agricultural University"
  },
  {
    "college": "Maharishi Dayanand University"
  },
  {
    "college": "National Law University Odisha"
  },
  {
    "college": "Lingaya's University"
  },
  {
    "college": "Berhampur University"
  },
  {
    "college": "Mahatma Gandhi Antarrashtriya Hindi Vishwavidyalaya"
  },
  {
    "college": "Marwadi University"
  },
  {
    "college": "Indira Gandhi National Tribal University"
  },
  {
    "college": "Karnavati University"
  },
  {
    "college": "School of Planning and Architecture, Vijayawada"
  },
  {
    "college": "Professor Jayashankar Telangana State Agricultural University"
  },
  {
    "college": "Homi Bhabha National Institute"
  },
  {
    "college": "CSK Himachal Pradesh Krishi Vishvavidyalaya"
  },
  {
    "college": "National Institute of Food Technology Entrepreneurship and Management"
  },
  {
    "college": "Maharaja Ganga Singh University"
  },
  {
    "college": "Raj Rishi Bharthari Matsya University"
  },
  {
    "college": "Central University of Karnataka"
  },
  {
    "college": "Jaypee University of Engineering and Technology"
  },
  {
    "college": "Prof. Rajendra Singh (Rajju Bhaiya) University"
  },
  {
    "college": "Chandra Shekhar Azad University of Agriculture and Technology"
  },
  {
    "college": "Binod Bihari Mahto Koyalanchal University"
  },
  {
    "college": "National Sanskrit University"
  },
  {
    "college": "Magadh University"
  },
  {
    "college": "Central University of Jharkhand"
  },
  {
    "college": "Chaudhary Bansi Lal University"
  },
  {
    "college": "Poornima University"
  },
  {
    "college": "Chaudhary Devi Lal University"
  },
  {
    "college": "Gurukul Kangri Vishwavidyalaya"
  },
  {
    "college": "Charotar University of Science and Technology"
  },
  {
    "college": "National Institute of Technology, Goa"
  },
  {
    "college": "JK Lakshmipat University"
  },
  {
    "college": "Srimanta Sankaradeva University of Health Sciences"
  },
  {
    "college": "Sanskriti University"
  },
  {
    "college": "Indira Gandhi University, Meerpur"
  },
  {
    "college": "Shri Lal Bahadur Shastri Rashtriya Sanskrit Vidyapeetha"
  },
  {
    "college": "MIT Art Design and Technology University"
  },
  {
    "college": "Indira Gandhi Krishi Vishwavidyalaya"
  },
  {
    "college": "Swami Vivekananda Yoga Anusandhana Samsthana"
  },
  {
    "college": "Singhania University"
  },
  {
    "college": "Ravenshaw University"
  },
  {
    "college": "Indian Institute of Teacher Education"
  },
  {
    "college": "Rajasthan University of Veterinary and Animal Sciences"
  },
  {
    "college": "Himachal Pradesh Technical University"
  },
  {
    "college": "Harcourt Butler Technical University"
  },
  {
    "college": "Kalinga University"
  },
  {
    "college": "Aryabhatta Knowledge University"
  },
  {
    "college": "Sambalpur University"
  },
  {
    "college": "Tilak Maharashtra Vidyapeeth"
  },
  {
    "college": "DAV University"
  },
  {
    "college": "Sri Sri University"
  },
  {
    "college": "Shobhit Institute of Engineering and Technology"
  },
  {
    "college": "Sandip University"
  },
  {
    "college": "Mahatma Phule Krishi Vidyapeeth"
  },
  {
    "college": "Tamil University"
  },
  {
    "college": "Sree Sankaracharya University of Sanskrit"
  },
  {
    "college": "Central Institute of Higher Tibetan Studies"
  },
  {
    "college": "Krishna Vishwa Vidyapeeth"
  },
  {
    "college": "Dr. Ram Manohar Lohiya National Law University"
  },
  {
    "college": "School of Planning and Architecture, Bhopal"
  },
  {
    "college": "Deenbandhu Chhotu Ram University of Science and Technology"
  },
  {
    "college": "Sage University"
  },
  {
    "college": "Kazi Nazrul University"
  },
  {
    "college": "Indira Gandhi Delhi Technical University for Women"
  },
  {
    "college": "Dr. C.V. Raman University"
  },
  {
    "college": "Karnataka State Law University"
  },
  {
    "college": "Mahatma Gandhi University of Medical Sciences and Technology"
  },
  {
    "college": "Govind Guru Tribal University"
  },
  {
    "college": "Jawaharlal Nehru Architecture and Fine Arts University"
  },
  {
    "college": "National Institute of Technology Mizoram"
  },
  {
    "college": "Sri Padmavati Mahila Visvavidyalayam"
  },
  {
    "college": "Central University of Gujarat"
  },
  {
    "college": "International Institute of Information Technology, Bhubaneswar"
  },
  {
    "college": "Krishna University"
  },
  {
    "college": "National Institute of Technology Sikkim"
  },
  {
    "college": "Chanakya National Law University"
  },
  {
    "college": "Madhav University"
  },
  {
    "college": "National Institute of Technology, Uttarakhand"
  },
  {
    "college": "Siddharth University"
  },
  {
    "college": "Jain Vishva Bharati Institute"
  },
  {
    "college": "National Institute of Technology, Arunachal Pradesh"
  },
  {
    "college": "Techno India University"
  },
  {
    "college": "National Institute of Technology, Puducherry"
  },
  {
    "college": "Lakshmibai National Institute of Physical Education"
  },
  {
    "college": "Maharshi Dayanand Saraswati University"
  },
  {
    "college": "Navrachana University"
  },
  {
    "college": "Chitkara University, Himachal Pradesh"
  },
  {
    "college": "Pacific University, India"
  },
  {
    "college": "Swami Rama Himalayan University"
  },
  {
    "college": "Karnataka State Akkamahadevi Women's University"
  },
  {
    "college": "Navsari Agricultural University"
  },
  {
    "college": "Rani Channamma University, Belagavi"
  },
  {
    "college": "Bihar Agricultural University"
  },
  {
    "college": "Indira Gandhi Institute of Medical Sciences"
  },
  {
    "college": "Maharana Pratap University of Agriculture and Technology"
  },
  {
    "college": "Lala Lajpat Rai University of Veterinary and Animal Sciences"
  },
  {
    "college": "University of Science and Technology, Meghalaya"
  },
  {
    "college": "Sri Venkateswara Veterinary University"
  },
  {
    "college": "GNA University"
  },
  {
    "college": "Telangana University"
  },
  {
    "college": "National University of Study and Research in Law"
  },
  {
    "college": "Dayananda Sagar University"
  },
  {
    "college": "Pratap University"
  },
  {
    "college": "Sri Balaji Vidyapeeth"
  },
  {
    "college": "National Law University and Judicial Academy"
  },
  {
    "college": "Ajeenkya D.Y. Patil University"
  },
  {
    "college": "Birsa Agricultural University"
  },
  {
    "college": "Medi-Caps University"
  },
  {
    "college": "Dr. Balasaheb Sawant Konkan Krishi Vidyapeeth"
  },
  {
    "college": "Nanaji Deshmukh Veterinary Science University"
  },
  {
    "college": "Meenakshi Academy of Higher Education and Research"
  },
  {
    "college": "Gangadhar Meher University"
  },
  {
    "college": "Central University of Jammu"
  },
  {
    "college": "Rayalaseema University"
  },
  {
    "college": "Guru Angad Dev Veterinary and Animal Sciences University"
  },
  {
    "college": "Mangalayatan University"
  },
  {
    "college": "IIHMR University"
  },
  {
    "college": "Shri Vishwakarma Skill University"
  },
  {
    "college": "Indian Institute of Information Technology, Sri City"
  },
  {
    "college": "Shri Vaishnav Vidyapeeth Vishwavidyalaya"
  },
  {
    "college": "National Institute of Technology, Nagaland"
  },
  {
    "college": "Veer Kunwar Singh University"
  },
  {
    "college": "ICFAI University, Tripura"
  },
  {
    "college": "Rajiv Gandhi National University of Law"
  },
  {
    "college": "Babu Banarasi Das University"
  },
  {
    "college": "Arunachal University of Studies"
  },
  {
    "college": "Sangam University"
  },
  {
    "college": "Mother Teresa Women's University"
  },
  {
    "college": "Dravidian University"
  },
  {
    "college": "BLDE University"
  },
  {
    "college": "Rishihood University"
  },
  {
    "college": "Deccan College Post-Graduate and Research Institute"
  },
  {
    "college": "Indian Institute of Information Technology, Vadodara"
  },
  {
    "college": "Sido Kanhu Murmu University"
  },
  {
    "college": "University of Trans-Disciplinary Health Sciences and Technology"
  },
  {
    "college": "Maharishi University of Management and Technology"
  },
  {
    "college": "Vivekananda Global University"
  },
  {
    "college": "Mahatma Gandhi University, Nalgonda"
  },
  {
    "college": "National Institute of Pharmaceutical Education and Research, Hyderabad"
  },
  {
    "college": "MGM Institute of Health Sciences"
  },
  {
    "college": "Rajiv Gandhi National Institute of Youth Development"
  },
  {
    "college": "Uttar Banga Krishi Viswavidyalaya"
  },
  {
    "college": "Davangere University"
  },
  {
    "college": "Pacific Medical University"
  },
  {
    "college": "Bengaluru North University"
  },
  {
    "college": "Jagran Lakecity University"
  },
  {
    "college": "Vasantrao Naik Marathwada Krishi Vidyapeeth"
  },
  {
    "college": "Dr. Panjabrao Deshmukh Krishi Vidyapeeth"
  },
  {
    "college": "Kamdhenu University"
  },
  {
    "college": "Dr. Shakuntala Misra National Rehabilitation University"
  },
  {
    "college": "Uttar Pradesh University of Medical Sciences"
  },
  {
    "college": "Shri Venkateshwara University"
  },
  {
    "college": "Jharkhand Rai University"
  },
  {
    "college": "O.P. Jindal University"
  },
  {
    "college": "Karpagam Academy of Higher Education"
  },
  {
    "college": "Indian Institute of Information Technology, Guwahati"
  },
  {
    "college": "Cooch Behar Panchanan Barma University"
  },
  {
    "college": "St. Xavier's University"
  },
  {
    "college": "National Institute of Technology, Manipur"
  },
  {
    "college": "AURO University"
  },
  {
    "college": "Mewar University"
  },
  {
    "college": "North Orissa University"
  },
  {
    "college": "People's University"
  },
  {
    "college": "West Bengal University of Teachers' Training, Education Planning and Administration"
  },
  {
    "college": "Periyar Maniammai Institute of Science and Technology"
  },
  {
    "college": "Kannada University"
  },
  {
    "college": "MIT University"
  },
  {
    "college": "Chaudhary Ranbir Singh University"
  },
  {
    "college": "ITM Vocational University"
  },
  {
    "college": "U.P. Pt. Deen Dayal Upadhyaya Veterinary Science University and Cattle Research Institute"
  },
  {
    "college": "Punyashlok Ahilyadevi Holkar Solapur University"
  },
  {
    "college": "Children's University"
  },
  {
    "college": "Maharashtra Animal and Fishery Sciences University"
  },
  {
    "college": "Kolhan University"
  },
  {
    "college": "Dr. B.R. Ambedkar University"
  },
  {
    "college": "Sri Venkateswara Institute of Medical Sciences"
  },
  {
    "college": "University of Agricultural Sciences, Raichur"
  },
  {
    "college": "Maharaja Surajmal Brij University"
  },
  {
    "college": "West Bengal University of Animal and Fishery Sciences"
  },
  {
    "college": "Mahatma Gandhi Central University, Motihari"
  },
  {
    "college": "Potti Sreeramulu Telugu University"
  },
  {
    "college": "Kaziranga University"
  },
  {
    "college": "International Institute of Information Technology, Naya Raipur"
  },
  {
    "college": "Himalayan University"
  },
  {
    "college": "Career Point University"
  },
  {
    "college": "Vijayanagara Sri Krishnadevaraya University"
  },
  {
    "college": "National Institute of Pharmaceutical Education and Research, Guwahati"
  },
  {
    "college": "Avantika University"
  },
  {
    "college": "ICFAI University, Dehradun"
  },
  {
    "college": "Al-Falah University"
  },
  {
    "college": "IEC University"
  },
  {
    "college": "Sher-i-Kashmir Institute of Medical Sciences"
  },
  {
    "college": "Shri Ramswaroop Memorial University"
  },
  {
    "college": "Tamil Nadu Dr. J. Jayalalithaa Fisheries University"
  },
  {
    "college": "Rajmata Vijayaraje Scindia Krishi Vishwavidyalaya"
  },
  {
    "college": "Indus University"
  },
  {
    "college": "Satavahana University"
  },
  {
    "college": "Sidho Kanho Birsha University"
  },
  {
    "college": "Bodoland University"
  },
  {
    "college": "Bankura University"
  },
  {
    "college": "Sher-e-Kashmir University of Agricultural Sciences and Technology of Jammu"
  },
  {
    "college": "Sardar Vallabhbhai Patel University of Agriculture and Technology"
  },
  {
    "college": "Sir Padampat Singhania University"
  },
  {
    "college": "Datta Meghe Institute of Higher Education & Research"
  },
  {
    "college": "Maharishi Mahesh Yogi Vedic Vishwavidyalaya"
  },
  {
    "college": "Uka Tarsadia University"
  },
  {
    "college": "Himgiri ZEE University"
  },
  {
    "college": "Indira Kala Sangeet Vishwavidyalaya"
  },
  {
    "college": "Yogi Vemana University"
  },
  {
    "college": "MATS University"
  },
  {
    "college": "Hemwati Nandan Bahuguna Uttarakhand Medical Education University"
  },
  {
    "college": "Central Agricultural University"
  },
  {
    "college": "Tamil Nadu National Law University"
  },
  {
    "college": "JIS University"
  },
  {
    "college": "Sarala Birla University"
  },
  {
    "college": "Shaheed Mahendra Karma Vishwavidyalaya"
  },
  {
    "college": "Delhi Pharmaceutical Sciences and Research University"
  },
  {
    "college": "Acharya Narendra Deva University of Agriculture & Technology"
  },
  {
    "college": "Fakir Mohan University"
  },
  {
    "college": "Sunrise University"
  },
  {
    "college": "Raiganj University"
  },
  {
    "college": "Sri Guru Ram Das University of Health Sciences"
  },
  {
    "college": "Ayush and Health Sciences University of Chhattisgarh"
  },
  {
    "college": "Karnataka Veterinary, Animal and Fisheries Sciences University"
  },
  {
    "college": "Tamil Nadu Physical Education and Sports University"
  },
  {
    "college": "Mahatma Gandhi Chitrakoot Gramoday Vishwavidyalaya"
  },
  {
    "college": "University of Technology"
  },
  {
    "college": "Thunchath Ezhuthachan Malayalam University"
  },
  {
    "college": "Raja Mansingh Tomar Music and Arts University"
  },
  {
    "college": "St. Peter's Institute of Higher Education and Research"
  },
  {
    "college": "Garden City University"
  },
  {
    "college": "Guru Nanak Dev University"
  },
  {
    "college": "Rani Lakshmi Bai Central Agricultural University"
  },
  {
    "college": "Institute of Infrastructure, Technology, Research and Management"
  },
  {
    "college": "RIMT University"
  },
  {
    "college": "Damodaram Sanjivayya National Law University"
  },
  {
    "college": "Maharishi University"
  },
  {
    "college": "Santosh University"
  },
  {
    "college": "Bhagat Phool Singh Mahila Vishwavidyalaya"
  },
  {
    "college": "National Institute of Pharmaceutical Education and Research, Raebareli"
  },
  {
    "college": "Symbiosis University of Applied Sciences"
  },
  {
    "college": "Dakshina Bharat Hindi Prachar Sabha"
  },
  {
    "college": "OPJS University"
  },
  {
    "college": "ISBM University"
  },
  {
    "college": "Rabindranath Tagore University"
  },
  {
    "college": "G.L.S. University"
  },
  {
    "college": "Rai University"
  },
  {
    "college": "Malwanchal University"
  },
  {
    "college": "Jagannath University"
  },
  {
    "college": "Indian Institute of Information Technology, Kalyani"
  },
  {
    "college": "Maharaja Ranjit Singh Punjab Technical University"
  },
  {
    "college": "Banda University of Agriculture and Technology"
  },
  {
    "college": "Forest Research Institute"
  },
  {
    "college": "Mahatma Gandhi University, Meghalaya"
  },
  {
    "college": "EIILM University"
  },
  {
    "college": "PDM University"
  },
  {
    "college": "ICFAI University, Meghalaya"
  },
  {
    "college": "Swami Keshwanand Rajasthan Agricultural University"
  },
  {
    "college": "National Sports University"
  },
  {
    "college": "Manipur Technical University"
  },
  {
    "college": "Sanchi University of Buddhist-Indic Studies"
  },
  {
    "college": "Suamandeep Vidyapeeth"
  },
  {
    "college": "National Institute of Pharmaceutical Education and Research, Ahmedabad"
  },
  {
    "college": "Sardarkrushinagar Dantiwada Agricultural University"
  },
  {
    "college": "Baddi University of Emerging Sciences and Technologies"
  },
  {
    "college": "Sardar Patel University of Police, Security and Criminal Justice"
  },
  {
    "college": "Maharashtra National Law University, Nagpur"
  },
  {
    "college": "GIET University"
  },
  {
    "college": "Bhupendra Narayan Mandal University"
  },
  {
    "college": "Dr. Sarvepalli Radhakrishnan Rajasthan Ayurved University"
  },
  {
    "college": "LNCT University"
  },
  {
    "college": "Usha Martin University"
  },
  {
    "college": "GSFC University"
  },
  {
    "college": "Dr K.N. Modi University"
  },
  {
    "college": "Indian Institute of Information Technology, Lucknow"
  },
  {
    "college": "Bhagwant University"
  },
  {
    "college": "Maharshi Panini Sanskrit Vishwavidyalaya"
  },
  {
    "college": "Jagan Nath University"
  },
  {
    "college": "University of Horticultural Sciences, Bagalkot"
  },
  {
    "college": "IMS Unison University"
  },
  {
    "college": "Rayat-Bahra University"
  },
  {
    "college": "Apeejay Stya University"
  },
  {
    "college": "Uttarakhand Sanskrit University"
  },
  {
    "college": "Jayoti Vidyapeeth Women's University"
  },
  {
    "college": "Srinivas University"
  },
  {
    "college": "Sri Guru Granth Sahib World University"
  },
  {
    "college": "Indian Institute of Public Health"
  },
  {
    "college": "Uttarakhand Ayurved University"
  },
  {
    "college": "Jodhpur National University"
  },
  {
    "college": "Nava Nalanda Mahavihara"
  },
  {
    "college": "Institute of Advanced Studies in Education"
  },
  {
    "college": "Karnataka Samskrit University"
  },
  {
    "college": "Sabarmati University"
  },
  {
    "college": "Khwaja Moinuddin Chishti Language University"
  },
  {
    "college": "Ponnaiyan Ramajayam Institute of Science and Technology"
  },
  {
    "college": "Nilamber-Pitamber University"
  },
  {
    "college": "Shri Guru Ram Rai University"
  },
  {
    "college": "Maharashtra National Law University Mumbai"
  },
  {
    "college": "Vikrama Simhapuri University"
  },
  {
    "college": "Martin Luther Christian University"
  },
  {
    "college": "Sampurnanand Sanskrit Vishvavidyalaya"
  },
  {
    "college": "Sankalchand Patel University"
  },
  {
    "college": "Guru Kashi University"
  },
  {
    "college": "Veer Chandra Singh Garhwali Uttarakhand University of Horticulture & Forestry"
  },
  {
    "college": "University of Agricultural and Horticultural Sciences, Shivamogga"
  },
  {
    "college": "Alakh Prakash Goyal Shimla University"
  },
  {
    "college": "Indian Institute of Information Technology, Pune"
  },
  {
    "college": "Baba Ghulam Shah Badhshah University"
  },
  {
    "college": "Mandsaur University"
  },
  {
    "college": "Kumar Bhaskar Varma Sanskrit and Ancient Studies University"
  },
  {
    "college": "Monad University"
  },
  {
    "college": "Jagadguru Rambhadracharya Handicapped University"
  },
  {
    "college": "Diamond Harbour Women's University"
  },
  {
    "college": "Dr. Y.S.R. Horticultural University"
  },
  {
    "college": "JSS Science and Technology University"
  },
  {
    "college": "Gondwana University"
  },
  {
    "college": "Rajiv Gandhi National Aviation University"
  },
  {
    "college": "Chhattisgarh Kamdhenu Vishwavidyalaya"
  },
  {
    "college": "Krantiguru Shyamji Krishna Verma Kachchh University"
  },
  {
    "college": "P P Savani University"
  },
  {
    "college": "BAHRA University"
  },
  {
    "college": "Maharaja Agrasen Himalayan Garhwal University"
  },
  {
    "college": "University of Patanjali"
  },
  {
    "college": "Cluster University of Jammu"
  },
  {
    "college": "Atal Bihari Vajpayee Hindi Vishwavidyalaya"
  },
  {
    "college": "The Neotia University"
  },
  {
    "college": "West Bengal State University"
  },
  {
    "college": "Maharashtra National Law University, Aurangabad"
  },
  {
    "college": "Jagadguru Ramanandacharya Rajasthan Sanskrit University"
  },
  {
    "college": "AKS University"
  },
  {
    "college": "Desh Bhagat University"
  },
  {
    "college": "Kameshwar Singh Darbhanga Sanskrit University"
  },
  {
    "college": "Bhatkhande Sanskriti Vishwavidyalaya"
  },
  {
    "college": "Utkal University of Culture"
  },
  {
    "college": "North East Frontier Technical University"
  },
  {
    "college": "Sanjay Ghodawat University"
  },
  {
    "college": "NIILM University"
  },
  {
    "college": "Shree Somnath Sanskrit University"
  },
  {
    "college": "ICFAI University, Jharkhand"
  },
  {
    "college": "Gujarat University of Transplantation Sciences"
  },
  {
    "college": "Jananayak Chandrashekhar University"
  },
  {
    "college": "ICFAI University, Sikkim"
  },
  {
    "college": "Indian Institute of Information Technology, Una"
  },
  {
    "college": "Adesh University"
  },
  {
    "college": "Oriental University"
  },
  {
    "college": "Arni University"
  },
  {
    "college": "Sri Satya Sai University of Technology and Medical Sciences"
  },
  {
    "college": "National Institute of Pharmaceutical Education and Research, Kolkata"
  },
  {
    "college": "Swami Vivekanand University"
  },
  {
    "college": "Eternal University"
  },
  {
    "college": "Dr. A.P.J Abdul Kalam University"
  },
  {
    "college": "Maharishi Markandeshwar University, Solan"
  },
  {
    "college": "IIMT University"
  },
  {
    "college": "Sri Konda Laxman Telangana State Horticultural University"
  },
  {
    "college": "SRM University, Sikkim"
  },
  {
    "college": "Assam Women's University"
  },
  {
    "college": "Rai Technology University"
  },
  {
    "college": "Shri Jagannath Sanskrit Vishvavidyalaya"
  },
  {
    "college": "Era University"
  },
  {
    "college": "Maulana Mazharul Haque Arabic and Persian University"
  },
  {
    "college": "Sai Nath University"
  },
  {
    "college": "Haridev Joshi University of Journalism and Mass Communication"
  },
  {
    "college": "Arka Jain University"
  },
  {
    "college": "Sarvepalli Radhakrishnan University"
  },
  {
    "college": "RKDF University"
  },
  {
    "college": "Sri Venkateswara Vedic University"
  },
  {
    "college": "Maharaja Agrasen University"
  },
  {
    "college": "The Sanskrit College and University"
  },
  {
    "college": "Bhartiya Skill Development University"
  },
  {
    "college": "Central University of Andhra Pradesh"
  },
  {
    "college": "G.H. Raisoni University"
  },
  {
    "college": "MVN University"
  },
  {
    "college": "Mahapurusha Srimanta Sankaradeva Viswavidyalaya"
  },
  {
    "college": "Geetanjali University"
  },
  {
    "college": "Shridhar University"
  },
  {
    "college": "Seacom Skills University"
  },
  {
    "college": "Apex Professional University"
  },
  {
    "college": "Sri Devaraj Urs Academy of Higher Education and Research"
  },
  {
    "college": "Shri Jagdishprasad Jhabrmal Tibrewala University"
  },
  {
    "college": "Sangai International University"
  },
  {
    "college": "Indian Institute of Information Technology, Manipur"
  },
  {
    "college": "Karnataka State Rural Development and Panchayat Raj University"
  },
  {
    "college": "Mohammad Ali Jauhar University"
  },
  {
    "college": "ICFAI University, Raipur"
  },
  {
    "college": "Maharaja Bir Bikram University"
  },
  {
    "college": "Sant Baba Bhag Singh University"
  },
  {
    "college": "Institute of Advanced Research"
  },
  {
    "college": "Kavi Kulguru Kalidas Sanskrit Vishwavidyalaya"
  },
  {
    "college": "Assam Rajiv Gandhi University of Cooperative Management"
  },
  {
    "college": "Tantia University"
  },
  {
    "college": "Akal University"
  },
  {
    "college": "Sri Sai University"
  },
  {
    "college": "Apex University"
  },
  {
    "college": "KSGH Music and Performing Arts University"
  },
  {
    "college": "Arunodaya University"
  },
  {
    "college": "Indrashil University"
  },
  {
    "college": "Mahatma Jyoti Rao Phoole University"
  },
  {
    "college": "ICFAI University, Himachal Pradesh"
  },
  {
    "college": "ICFAI University, Jaipur"
  },
  {
    "college": "Manav Bharti University"
  },
  {
    "college": "National Institute of Pharmaceutical Education and Research, Hajipur"
  },
  {
    "college": "Swarnim Startup and Innovation University"
  },
  {
    "college": "Spicer Adventist University"
  },
  {
    "college": "Kushabhau Thakre Patrakarita Avam Jansanchar University"
  },
  {
    "college": "Guru Ravidas Ayurved University"
  },
  {
    "college": "Swarnim Gujarat Sports University"
  },
  {
    "college": "Tamil Nadu Dr.J Jayalalithaa Music and Fine Arts University"
  },
  {
    "college": "Uttarakhand Aawasiya Vishwavidyalaya, Almora"
  },
  {
    "college": "TeamLease Skills University"
  },
  {
    "college": "Kadi Sarva Vishwavidyalaya"
  },
  {
    "college": "Bhupal Nobles University"
  },
  {
    "college": "Raffles University"
  },
  {
    "college": "St. Joseph University"
  },
  {
    "college": "Karnataka Janapada Vishwavidyalaya"
  },
  {
    "college": "C.U. Shah University"
  },
  {
    "college": "Jaypee University Anoopshahr"
  },
  {
    "college": "ICFAI University, Mizoram"
  },
  {
    "college": "Atmiya University"
  },
  {
    "college": "Dr. B.R. Ambedkar University of Social Sciences"
  },
  {
    "college": "Starex University"
  },
  {
    "college": "CMJ University"
  },
  {
    "college": "Maharishi Markandeshwar University, Sadopur"
  },
  {
    "college": "Jharkhand Raksha Shakti University"
  },
  {
    "college": "Rama Devi Women's University"
  },
  {
    "college": "Vinayaka Missions Sikkim University"
  },
  {
    "college": "Nehru Gram Bharati Vishwavidyalaya"
  },
  {
    "college": "K.K. University"
  },
  {
    "college": "Indus International University"
  },
  {
    "college": "William Carey University, Shillong"
  },
  {
    "college": "Bareilly International University"
  },
  {
    "college": "State University of Performing and Visual Arts"
  },
  {
    "college": "ICFAI University, Nagaland"
  },
  {
    "college": "Plastindia International University"
  },
  {
    "college": "Sharnbasva University"
  },
  {
    "college": "Murshidabad University"
  },
  {
    "college": "Abhilashi University"
  },
  {
    "college": "Pragyan International University"
  },
  {
    "college": "Madhyanchal Professional University"
  },
  {
    "college": "Manipur International University"
  },
  {
    "college": "P.K. University"
  },
  {
    "college": "Manipur University of Culture"
  },
  {
    "college": "Ras Bihari Bose Subharti University"
  },
  {
    "college": "Bhabha University"
  },
  {
    "college": "YBN University"
  },
  {
    "college": "Dr YSR Architecture and Fine Arts University"
  },
  {
    "college": "Indira Gandhi Technological and Medical Sciences University"
  },
  {
    "college": "Maharishi Arvind University, Jaipur"
  },
  {
    "college": "Sai Tirupati University"
  },
  {
    "college": "Lakulish Yoga University"
  },
  {
    "college": "Maulana Azad University, Jodhpur"
  },
  {
    "college": "Shri Guru Ram Rai Education Mission"
  },
  {
    "college": "Manav Rachna University"
  },
  {
    "college": "Manav Rachna International Institute of Research and Studies"
  },
  {
    "college": "Sandip University, Sijoul"
  }
];
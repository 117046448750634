import React, { useRef } from "react";
import Button from "../common/Button";
import "./heroSection.css";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Lottie from "lottie-react";
import BrandsLove from "../BrandsLove";
// import animationData from '/home/anirudh/Downloads/overreacted-cred (1)/overreacted-cred/src/assets/Animation - 1704210697790.json'

const HeroSection = ({ scrollToBrandsLove }) => {
  const [typeEffect] = useTypewriter({
    words: [
      "simplify college",
      "manage tasks",
      "stay connected",
      "share resources",
    ],
    loop: {},
    typeSpeed: 100,
    deleteSpeed: 40,
  });

  return (
    <div className="hero-section-wrapper">
      <div className="flex absolute-center hero-claim-label">
        <div>exclusive launch for highest enrolled colleges.</div>
        <div className="claim-anchor">
          <button
            style={{ all: "unset", cursor: "pointer" }}
            onClick={scrollToBrandsLove}
          >
            apply now
            <img
              src="https://web-images.credcdn.in/_next/assets/images/home-page/arrow.png"
              className="claim-arrow"
            />
          </button>
        </div>
      </div>
      <div className="flex absolute-center flex-col hero-section max-width">
        <div className="hero-heading type-writer">
          one app to <br />
          {typeEffect}{" "}
          <img
            src="https://sandeep049.sirv.com/Images/Personal%20lines%20of%20credit%20(2)/Personal%20lines%20of%20credit%20(4)/65-removebg-preview.png"
            className="boat-animation-2"
          />
        </div>
        <div className="hero-subheading">
          simplify college life, cruise away with Sailr
        </div>
        {/*<Lottie animationData={animationData} />*/}

        <div className="flex absolute-center">
          <Button buttonText="Join Sailr" onClick={scrollToBrandsLove} />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

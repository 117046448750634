import React from "react";
import "./credStory.css";

const CredStory = React.forwardRef((props, ref) => {
  const emailAddress = "captian@sailrapp.com"; // Replace with your actual email address

  const handleMailButtonClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };
  return (
    <div className="cred-story flex absolute-center" ref={ref}>
      <div className="max-width flex story-wrapper">
        <div className="flex cred-story-heading-wrapper">
          <div className="cred-story-heading">reach out to us. drop a suggestion.</div>
        </div>
        <div className="cred-story-detail">
          <div className="cred-story-para">
            sailr is an app to create a college community to conect students in
            a single platform for all college related stuff. be it managing
            classes, attendance or tracking finances and sorting mails, we have
            got all your daily tasks covered.
          </div>
          <div className="cred-story-para">
            for clubs and groups, connect and interact with your audience in a
            better way. with announcements and feeds separated, prioritize your
            club's engagement. for students, follow and see only your favorite
            clubs. no bs.
          </div>
          <div
            className="cred-story-para"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div style={{ marginRight: 20 }}>
              <a
                href="https://x.com/xSailr?s=20"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/5/57/X_logo_2023_%28white%29.png"
                  alt="Twitter Logo"
                  width="40"
                  height="40"
                />
              </a>
            </div>
            <div style={{ marginRight: 20 }}>
              <a
                href="https://discord.gg/BFSPYkVnad"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6a49cf127bf92de1e2_icon_clyde_blurple_RGB.png"
                  alt="Discord Logo"
                  width="50"
                  height="38"
                />
              </a>
            </div>
            <div>
              <button
                onClick={handleMailButtonClick}
                style={{ cursor: "pointer", all: "unset" }}
              >
                <img
                  src="https://img.icons8.com/?size=256&id=Ww1lcGqgduif&format=png"
                  alt="Email Logo"
                  width="45"
                  height="45"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CredStory;

import React from "react";

const Footer = () => {
  return (
    <div>
      <div className="flex absolute-center footer">
        Made For Students By Students
        <img
          src="https://sandeep049.sirv.com/Images/Personal%20lines%20of%20credit%20(2)/Personal%20lines%20of%20credit%20(4)/65-removebg-preview.png"
          className="boat-animation-2"
        />
      </div>
    </div>
  );
};

export default Footer;

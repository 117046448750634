import React, { useRef } from "react";
import HeroSection from "../components/HeroSection";
import Header from "../components/common/Header";
import ProductShowcase from "../components/ProductShowcase";
import FeelSpecial from "../components/FeelSpecial";
import CredExperience from "../components/CredExperience";
import CredSecurity from "../components/CredSecurity";
import BrandsLove from "../components/BrandsLove";
import WindowPeak from "../components/WindowPeak";
import MobileScroll from "../components/MobileScroll";
import CredStory from "../components/CredStory";
import AppRating from "../components/AppRating";
import Footer from "../components/common/Footer";

const HomePage = () => {
  const brandsLoveRef = useRef(null);
  const contactUsRef = useRef(null);

  const scrollToBrandsLove = () => {
    if (brandsLoveRef.current) {
      brandsLoveRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const scrollToContactUs = () => {
    if (contactUsRef.current) {
      contactUsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "black",
        }}
      >
        <Header
          scrollToBrandsLove={scrollToBrandsLove}
          scrollToContactUs={scrollToContactUs}
        />
        <HeroSection scrollToBrandsLove={scrollToBrandsLove} />

        <ProductShowcase />
        {/* <FeelSpecial /> */}
        <BrandsLove ref={brandsLoveRef} />
        {/*<CredExperience />*/}
        <MobileScroll />
        {/* <div className="non-mobile"> */}
        <WindowPeak />
        {/* </div> */}
        {/*<CredSecurity />*/}
        <CredStory ref={contactUsRef} />
        {/*<AppRating />*/}
        <Footer />
      </div>
    </>
  );
};

export default HomePage;

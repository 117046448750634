import React, { useState } from 'react'
import "./mobileScroll.css"
import ScreenText from './ScreenText'  

const scrollData = [
    {
        heading: "simplify your daily tasks.",
        description1: "📌 class schedules and attendance.",
        description2: "🤖 AI-powered e-mail sorting.",
        description3: "💸 finance management.",
        mobile_img: "https://sandeep049.sirv.com/Images/scrolls/pg1.png",
    },

    {
        heading: "peer-to-peer marketplace.",
        description1: "🔰 buy/sell/rent from your college mates.",
        description2: "🗿 products, services, anything.",
        description3: "💯 save more. earn more.",
        mobile_img: "https://sandeep049.sirv.com/Images/scrolls/pg2.png",
    },

    {
        heading: "clubs just got a new platform.",
        description1: "🎙️ manage your club announcements.",
        description2: "👥 reach more and specific audience.",
        description3: "🕊️ following your interests made easy.",
        mobile_img: "https://sandeep049.sirv.com/Images/scrolls/pg3_2.png",
    },

    {
        heading: "one-stop hub for academic resources.",
        description1: "🔰 buy and share resources.",
        description2: "📖 notes, tutorials, papers.",
        description3: "⭐ in-app ratings.",
        mobile_img: "https://sandeep049.sirv.com/Images/scrolls/pg4.png",
    }
]

const MobileScroll = () => {

    const [currentImg, setCurrentImg] = useState(0)

  return (
    <div className='max-width flex mobile-scroll'>
        <div className='scroll-full-screen-wrapper'>
            {scrollData.map((screen, i) => (
                <div className='scroll-full-screen'> 
                    <ScreenText screen={screen} setCurrentImg={setCurrentImg} i={i} />
                </div>
            ))}
        </div>
        <div className='mobile-mockup-wrapper non-mobile'>
        {/*<div className='mobile-mockup-wrapper'>*/}
            <div className='mobile-mockup'>
                <div className='mobile-mockup-screen flex absolute-center'>
                    <img 
                        src={scrollData[currentImg].mobile_img}
                        className='mobile-screen-img slide-in-right'
                        key={scrollData[currentImg].mobile_img}
                    />
                </div>
            </div>
        </div>
    </div>
  )
}

export default MobileScroll
import React, { useRef, useState, useEffect } from "react";
import "./brandsLove.css";
import Select from "react-select";
import { isVisible } from "@testing-library/user-event/dist/utils";
import Loader2 from "../common/loading/loader2";
import { colleges } from "./list";

const BrandsLove = React.forwardRef((props, ref) => {
  const [email, setEmail] = useState("");
  const [currentCounts, setCurrentCounts] = useState([0, 0, 0]); // Initial values
  const [collegeName, setcollegeName] = useState();
  const intersectionObserverRef = useRef(null);
  const [isPressed, setIsPressed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [successMsg, setsuccessMsg] = useState(false);
  const [mehMsg, setmehMsg] = useState(false);
  const [failMsg, setfailMsg] = useState(false);
  // ... (other state variables)
  const [data, setdata] = useState([]);
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://d2ezq2nupxusya.cloudfront.net/Colleges/?top_5=true",
        {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",

            // Add any other headers as needed
          },
          // Add other fetch options if needed
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setdata(data.slice(0, -2));
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError(null);
    }
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  // useEffect(() => {
  //   intersectionObserverRef.current = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           startIncrementingAnimation();
  //           intersectionObserverRef.current.unobserve(entry.target);
  //         }
  //       });
  //     },
  //     { threshold: 0.5 } // Adjust the threshold as needed
  //   );

  //   intersectionObserverRef.current.observe(ref.current);

  //   return () => {
  //     if (intersectionObserverRef.current) {
  //       intersectionObserverRef.current.disconnect();
  //     }
  //   };
  // }, [ref]);

  // const startIncrementingAnimation = () => {
  //   const interval = setInterval(() => {
  //     setCurrentCounts((prevCounts) =>
  //       prevCounts.map((countt, index) =>
  //         countt < data[index].count
  //           ? countt + Math.ceil((data[index].count - countt) / 10)
  //           : countt
  //       )
  //     );
  //   }, 100);

  //   setTimeout(() => {
  //     clearInterval(interval);
  //   }, 2000); // Adjust the duration of the animation as needed
  // };
  // const handleSubmit = () => {
  //   // Handle form submission logic here
  //   console.log("Email:", email);
  //   console.log("College Name:", collegeName.value);
  //   setIsLoading(true);

  //   // Simulate an asynchronous operation (e.g., API call) with setTimeout
  //   setTimeout(() => {
  //     setIsLoading(false);
  //     setIsSubmitted(true);
  //   }, 3000);
  // };
  const handleSubmit = async () => {
    // Assuming you have the API endpoint and data to send
    const apiUrl = "https://d2ezq2nupxusya.cloudfront.net/waitListLog/"; // Replace with your actual API endpoint
    const requestData = {
      email: email,
      collegeName: collegeName.value,
      // Add other data properties as needed
    };

    try {
      setIsLoading(true);

      // Make a POST request to your API using fetch
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
        referrerPolicy: "unsafe-url",
      });
      const responseData = await response.json();

      if (response.ok) {
        if ("id" in responseData) {
          setsuccessMsg(true);
        } else if ("detail" in responseData) {
          setmehMsg(true);
        }
        console.log("API call successful", responseData);
      } else {
        setfailMsg(true);
        console.error("API call failed", responseData);
      }
    } catch (error) {
      setfailMsg(true);
      console.error("An error occurred during API call", error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        setIsSubmitted(true);
        fetchData();
      }, 1000);
      // setIsLoading(false);
      // setIsSubmitted(true);
    }
  };

  // Convert colleges array to options array
  const options = colleges.map((college) => ({
    value: college.college,
    label: college.college,
  }));

  options.push({ value: "other", label: "Other" });

  // Handle selection change
  const handleCollegeChange = (selectedOption) => {
    setcollegeName(selectedOption);
  };
  const resetCollegeName = () => {
    setcollegeName(null);
  };
  return (
    <div className="brands-love" ref={ref}>
      <div
        className="max-width"
        style={{
          marginBottom: 100,
          justifyContent: "center",
          alignContent: "center",
        }}
      ></div>
      <div className="brand-story flex absolute-center">
        <div className="max-width flex story-wrapper">
          {/* <div className="flex brand-story-heading-wrapper"> */}
          {/* <div className="brand-story-heading"> */}
          <div className="leaderboard-cont">
            <div className="leaderboard-cont-top-box">
              <img
                src="https://sandeep049.sirv.com/Images/Personal%20lines%20of%20credit%20(2)/Personal%20lines%20of%20credit%20(4)/Personal_lines_of_credit__17_-removebg-preview.png"
                class="header-logo"
                width={60}
                height={50}
              />
            </div>
            {/* <div class="app-title">Goal Scorers</div> */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div className="brands-love-lead">
                <p>Leading Colleges</p>
              </div>

              <div class="app-table">
                {data.map((item, index) => (
                  <div key={index} class="app-row">
                    <div class="player">
                      <div class="rank">{index + 1}</div>

                      <div class="name">{item.college}</div>
                      <div class="image">
                        <img
                          src={item.logo}
                          alt={`Image ${index}`}
                          style={{ width: "50px", height: "50px" }}
                        />
                      </div>
                      <div class="goals">
                        <div className="brands-love-count">
                          <p>{item.count}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
          <div className="brand-story-detail">
            <div className="brands-love-heading">
              exclusive launch for highest enrolled colleges.
            </div>
            {!isSubmitted && !isLoading && (
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  // paddingInline: 100,
                  // margin: 10,
                  marginTop: 100,
                }}
              >
                <div style={{ color: "black" }}>
                  {collegeName && collegeName.label === "Other" ? (
                    <div
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Enter College"
                        onChange={(e) =>
                          setcollegeName({
                            label: "Other",
                            value: e.target.value,
                          })
                        }
                        style={{
                          borderRadius: 20,
                          fontSize: 20,
                          // width: 320,
                          backgroundColor: "rgba(255, 255, 255, 0.4)",
                          color: "black",
                          placeholder: (base) => ({ ...base, color: "black" }),
                          paddingLeft: 50,
                          padding: 15,
                          borderColor: "blueviolet",
                          textEmphasisColor: "cadetblue",
                        }}
                      />
                      <button
                        onClick={resetCollegeName}
                        style={{
                          border: "none",
                          background: "none",
                          cursor: "pointer",
                          fontSize: 30,
                        }}
                      >
                        &#10005;
                      </button>
                    </div>
                  ) : (
                    <Select
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          borderRadius: 20,
                          padding: 9,
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                          fontSize: 20,
                          paddingLeft: 20,
                          color: "black",
                          placeHolder: (base) => ({
                            ...base,
                            color: "black",
                          }),
                          searchTerm: (base) => ({
                            ...base,
                            color: "white",
                          }),
                          borderColor: "blueviolet",
                          control: (provided, state) => ({
                            ...provided,
                            boxShadow: "none",
                            border: "none",
                            width: "100%",
                          }),
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                            color: "white",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 10000,
                            backgroundColor: "rgba(0, 0, 0, 0.4)",
                          }),
                          menuList: (base) => ({
                            ...base,
                            maxHeight: 500,
                            overflowY: "auto",

                            backgroundColor: "rgba(0, 0, 0, 0.4)",
                          }),
                        }),
                      }}
                      value={collegeName}
                      onChange={handleCollegeChange}
                      options={options}
                      placeholder="Select a college"
                      isSearchable
                      setSearchTerm
                      isClearable
                      filterOption={({ label }, input) => {
                        // Display "Other" as default option regardless of the search term
                        if (label.toLowerCase() === "other") return true;
                        // Filter options based on the search term
                        return label
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      isMulti={false}
                      // menuPosition="fixed"
                    />
                  )}
                </div>
                <br />
                <input
                  style={{
                    borderRadius: 20,
                    fontSize: 20,
                    // width: 320,
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    color: "black",
                    placeholder: (base) => ({ ...base, color: "black" }),
                    paddingLeft: 50,
                    padding: 15,
                    borderColor: "blueviolet",
                    textEmphasisColor: "cadetblue",
                  }}
                  type="email"
                  placeholder="enter your college email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => validateEmail(email)} // onBlur event for email validation
                />
                {emailError && (
                  <div style={{ color: "white" }}>{emailError}</div>
                )}

                <br />
                <div
                  style={{
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    className={`gradient-button ${isHovered ? "hovered" : ""} ${
                      isPressed ? "pressed" : ""
                    }`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onClick={handleSubmit}
                    disabled={
                      emailError !== null ||
                      !email.trim() ||
                      collegeName === null
                    } // Disable if there's an email error or email is empty
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
            {isLoading && !isSubmitted && (
              <div
                style={{
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {/* Add your loader component or message here */}
                <Loader2 option={"loading"} h={300} w={300} />
              </div>
            )}

            {/* Submission success message */}
            {isSubmitted && (
              <div
                style={{
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {successMsg && (
                  <div
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        fontSize: 34,
                        textAlign: "center",
                      }}
                    >
                      Your response is recorded successfully!
                    </p>
                    <Loader2 option={"success"} h={300} w={300} />
                  </div>
                )}
                {failMsg && (
                  <div
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "orange",
                        fontSize: 34,
                        textAlign: "center",
                      }}
                    >
                      We are really sorry something went wrong with us!
                    </p>
                    <Loader2 option={"failed"} h={200} w={200} />
                  </div>
                )}
                {mehMsg && (
                  <div
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "yellow",
                        fontSize: 34,
                        textAlign: "center",
                      }}
                    >
                      You have already signed up with us!
                    </p>
                    <Loader2 option={"exist"} h={200} w={200} />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default BrandsLove;

import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";

import * as loadingAni from "/home/anirudh/ReactApps/SailrWeb/src/components/common/loading/Animation - 1704887148293.json";
import * as successAni from "/home/anirudh/ReactApps/SailrWeb/src/components/common/loading/Animation - 1704887379035.json";
import * as errorAni from "/home/anirudh/ReactApps/SailrWeb/src/components/common/loading/Animation - 1704887467275.json";
import * as ani4 from "/home/anirudh/ReactApps/SailrWeb/src/components/common/loading/Animation - 1704887563903.json";
import * as existAni from "/home/anirudh/ReactApps/SailrWeb/src/components/common/loading/Animation - 1704887583706.json";

const Loader2 = ({ option, h = 200, w = 200 }) => {
  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: loadingAni.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: successAni.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions3 = {
    loop: true,
    autoplay: true,
    animationData: errorAni.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions4 = {
    loop: true,
    autoplay: true,
    animationData: ani4.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions5 = {
    loop: true,
    autoplay: true,
    animationData: existAni.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // ... (your existing code for defaultOptions1, defaultOptions3, etc.)

  const getOptions = () => {
    switch (option) {
      case "loading":
        return defaultOptions1;
      case "success":
        return defaultOptions2;
      case "failed":
        return defaultOptions3;
      case "custom":
        return defaultOptions4;
      case "exist":
        return defaultOptions5;
      default:
        return defaultOptions1; // Default to loading animation if the option is not recognized
    }
  };

  const [data, setData] = useState([]);
  const [loading, setloading] = useState(undefined);
  const [completed, setcompleted] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      fetch("https://jsonplaceholder.typicode.com/posts")
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          setData(json);
          setloading(true);

          setTimeout(() => {
            // Do something after 2000 milliseconds
          }, 2000);
        });
    }, 2000);
  }, []);

  const options = getOptions();

  return (
    <>
      <Lottie options={options} height={h} width={w} />
    </>
  );
};

export default Loader2;

import React, { useState } from "react";
import "./header.css";

const Header = ({ scrollToBrandsLove, scrollToContactUs }) => {
  const [showMobMenu, setShowMobMenu] = useState(false);
  const toggleMobileMenu = () => {
    setShowMobMenu(!showMobMenu);
  };
  return (
    <div className="mobile-menu-wrapper">
      <div
        className={`mobile-menu only-mobile ${showMobMenu ? "overlay" : ""}`}
      >
        <div className="mobile-navbar">
          <div className="mobile-nav-item">college leaderboard</div>
          <div className="mobile-nav-item">contact sailr</div>
        </div>
      </div>

      <div className="flex max-width header">
        <img
          src="https://sandeep049.sirv.com/Images/Personal%20lines%20of%20credit%20(2)/Personal%20lines%20of%20credit%20(4)/Personal_lines_of_credit__17_-removebg-preview.png"
          className="header-logo"
        />
        <div className="only-mobile mobile-menu-button-wrapper">
          <button
            className={`hamburger hamburger--spin ${
              showMobMenu ? "is-active" : ""
            }`}
            type="button"
            onClick={toggleMobileMenu}
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
        <div className="non-mobile flex">
          <div className="header-nav-item">
            <button
              style={{ all: "unset", cursor: "pointer" }}
              onClick={scrollToBrandsLove}
            >
              college leaderboard
            </button>
          </div>
          <div className="header-nav-item">
            <button
              style={{ all: "unset", cursor: "pointer" }}
              onClick={scrollToContactUs}
            >
              contact sailr
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

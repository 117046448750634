import React, { useEffect, useRef, useState } from 'react'
import "./productShowcase.css"

const ProductShowcase = () => {

    const [showAnimation, setShowAnimation] = useState(false)

    const ref = useRef(null)

    const toggleAnimation = (e) => {
        if(e[0]?.isIntersecting){
            setShowAnimation(true)
        }
    }

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.4
    }


    useEffect(()=>{
        const observer = new IntersectionObserver(toggleAnimation, options)
        
        if (!showAnimation){
            if(ref.current){
                observer.observe(ref.current)
            }
        }

        return () => {
            if(ref.current){
                observer.unobserve(ref.current)
            }
        }
    })

  return (

    <div className='product-showcase-background'>

    <div 
        className={`product-showcase ${showAnimation ? 'scale-in-bottom' : ""}`}
        ref = {ref}
    >
        {showAnimation && (
        <div className='showcase-wrapper'>
            <img 
                src="https://sandeep049.sirv.com/Images/3-removebg-preview.png" 
                className='showcase-ui showcase-mockup-1'
            />
            <img 
                src="https://sandeep049.sirv.com/Images/2-removebg-preview.png" 
                className='showcase-ui showcase-mockup-2'
            />
            <img 
                src="https://sandeep049.sirv.com/Images/showcase__1_-removebg-preview.png" 
                className='showcase-ui showcase-mockup-3'
            />
            <img 
                src="https://sandeep049.sirv.com/Images/4-removebg-preview.png" 
                className='showcase-ui showcase-mockup-4'
            />
            <img 
                src="https://sandeep049.sirv.com/Images/5-removebg-preview.png" 
                className='showcase-ui showcase-mockup-5'
            />

        </div>
)}

        

    </div>

    </div>
  )
}

export default ProductShowcase